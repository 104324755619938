import React from "react";
import { Helmet } from "react-helmet";
import BasicSection from "../components/BasicSection";
import BigPicture from "../components/BigPicture";
import Columns from "../components/Columns";
import JoinDiscord from "../components/JoinDiscord";
import Screenshots from "../components/Screenshots";

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Virtuelle Marineflieger</title>
      </Helmet>
      <BigPicture />
      <BasicSection>
        <h1>Willkommen bei den virtuellen Marinefliegern</h1>
        <p>
          Wir sind eine Gruppe von Simulator Piloten. Kern unserer Gruppe ist
          eine F/A-18C Staffel im Digital Combat Simulator (DCS). Jeden Montag
          und Donnerstag fliegen wir ab 19:30 Uhr bis 22:00 Uhr gemeinsame
          Trainings mit Theorie und Praxisanteil. Wir sammeln gemeinsam Wissen
          rund um die Marinefliegerei. Neben Trainings fliegen wir regelmäßig
          PvE Missionen und wollen in Zukunft auch mehr PvP fliegen.
        </p>
        <p>
          Wir legen Wert auf echte Prozeduren. Briefings vor und nach dem Flug
          mithilfe von eigenen Unterlagen und TACVIEW gehören für uns dazu.
          Genauso wie Kommunikation über SIMPLE RADIO (SRS) bei der wir reale
          Verfahren anwenden. Die Freude an DCS kommt bei uns auch aus der
          tieferen Beschäftigung mit dem Thema Marinefliegerei und nicht nur aus
          dem Cockpit. Da wo wir können nutzen wir offen zugängliche
          Vorschriften und Verfahren aus allen Quellen um uns
          weiterzuentwickeln. Wenn nötig adaptieren wir diese dann für die
          Simulation.
        </p>
        <p>
          Bei uns gibt es keine Dienstgrade oder militärische Strukturen. Wir
          sind alle Berufstätig und planen unsere Flüge im Voraus. Obwohl es bei
          uns keine Anwesenheitspflicht gibt, ist Zuverlässigkeit und gutes
          kommunikationsverhalten eine Voraussetzung um bei uns mitzufliegen.
          Als Gastpilot heißen wir jeden Willkommen der bereit ist sich
          selbständig auf ein gutes fliegerisches Niveau zu bringen.
        </p>
      </BasicSection>
      <BasicSection style={{ paddingTop: 0 }}>
        <Screenshots from={0} to={3} />
      </BasicSection>
      <BasicSection variant="blue">
        <Columns>
          <div>
            <h2>Wir bieten:</h2>
            <ul>
              <li>Montags / Donnerstags Training um 19:30 Uhr</li>
              <li>Theorie und Praxis zu realen F/A-18C Verfahren</li>
              <li>Flugbriefing und Debriefing sowie Analyse</li>
              <li>Auf realen Vorschriften basierende Verfahren</li>
              <li>Eigener Dedicated Server mit SRS Simple Radio</li>
            </ul>
          </div>
          <div>
            <h2>Wir nutzen:</h2>
            <ul>
              <li>
                <a
                  href="https://www.digitalcombatsimulator.com/en/products/world/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DCS World Open Beta
                </a>
              </li>
              <li>
                <a
                  href="https://www.digitalcombatsimulator.com/en/shop/modules/supercarrier/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DCS: Supercarrier
                </a>
              </li>
              <li>
                <a
                  href="http://dcssimpleradio.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SRS - DCS Simple Radio Standalone
                </a>
              </li>
              <li>
                <a
                  href="https://discord.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord
                </a>
              </li>
              <li>
                <a
                  href="https://www.tacview.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TacView
                </a>
              </li>
              <li>
                <a
                  href="https://www.lotatc.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LotATC
                </a>
              </li>
              <li>
                <a
                  href="https://www.combatflite.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Combat Flite
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2>Wir suchen:</h2>
            <ul>
              <li>F/A-18C Piloten mit Grundlagen in Avionik, AAR, CASE 1</li>
              <li>
                Helikopter Piloten für den Aufbau einer Hubschrauberstaffel
              </li>
            </ul>
          </div>
        </Columns>
      </BasicSection>
      <BasicSection>
        <h2>Du hast Interesse bei uns mitzufliegen?</h2>
        <p>
          Du weißt schon wie die F/A-18C grundsätzlich funktioniert, arbeitest
          an deinen Fähigkeiten bei Carrier Recovery, Luftbetankung und
          Formationsflug. Es ist dir nicht fremd erstmal Online nach Lösungen zu
          suchen bevor du einfache Fragestellungen an andere weitergibst. Nach
          einer gewissen Zeit könntest du dir Vorstellen selbst ein Training zu
          planen und durchzuführen. Dann könnte es dir bei uns gefallen.
          Solltest du die Voraussetzungen noch nicht erfüllen oder ein anderes
          Muster fliegen, kannst du dich gerne trotzdem bei uns melden.
        </p>
        <p>
          Wir wollen in Zukunft gern die F-14B Komponente weiter ausbauen, hier
          haben wir Bedarf an weiteren erfahrenen Piloten die gerne Wissen an
          andere weitergeben. Für Interessenten an Helikoptern haben wir
          ebenfalls Plätze frei. In Zukunft wird es eine Hubschrauberstaffel
          geben.
        </p>
        <p>
          Einsteigern in die Multiplayerfliegerei helfen wir bei freien
          Kapazitäten gern.
        </p>
        <p>
          Wir freuen uns alle auf gemeinsame Events und ich lade jeden gern ein,
          einmal bei uns mitzufliegen. Der erste Schritt ist ein Gespräch auf
          unserem Discord. Schreibe einfach kurz und wir machen eine Zeit aus!
        </p>
      </BasicSection>
      <BasicSection style={{ paddingTop: 0 }}>
        <Screenshots from={3} to={9} />
      </BasicSection>
      <BasicSection variant="discord">
        <JoinDiscord />
      </BasicSection>
    </>
  );
}
