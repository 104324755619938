import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { BigPictureStaticQuery } from "../../graphql-types";
import styled from "styled-components";
import Title from "../images/name.svg";

export default function BigPicture() {
  const data = useStaticQuery<BigPictureStaticQuery>(graphql`
    query BigPictureStatic {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(quality: 80, width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      bigpicture: file(relativePath: { eq: "bigpicture.png" }) {
        childImageSharp {
          fluid(quality: 80, toFormat: JPG, maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  const logo = data?.logo?.childImageSharp?.fixed;
  const bigpicture = data?.bigpicture?.childImageSharp?.fluid;
  if (!logo || !bigpicture) {
    return null;
  }

  return (
    <Container>
      <Img
        fluid={bigpicture}
        alt="Big Picture"
        style={{ width: "100vw", maxWidth: "100vw" }}
      />

      <LogoContainer>
        <Img
          fixed={logo}
          alt="Logo"
          objectFit="contain"
          style={{ height: "auto" }}
          imgStyle={{ objectFit: "contain" }}
        />

        <Title style={{ width: "auto" }} aria-label="Virtuelle Marineflieger" />
      </LogoContainer>
    </Container>
  );
}

const Container = styled.section`
  position: relative;
  width: 100vw;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 32px;
  right: 32px;
  height: 150px;
  display: flex;

  @media (max-width: 575px) {
    top: 8px;
    left: 8px;
    right: 8px;
    height: 120px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
