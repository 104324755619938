import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ScreenshotsQuery } from "../../graphql-types";
import Img from "gatsby-image";
import { useImageViewer } from "react-image-viewer-hook";

interface Props {
  from?: number;
  to?: number;
}

export default function Screenshots({ from, to }: Props) {
  const data = useStaticQuery<ScreenshotsQuery>(graphql`
    query Screenshots {
      screenshots: allFile(
        sort: { order: [ASC], fields: [name] }
        filter: { relativeDirectory: { eq: "screenshots" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 80, toFormat: JPG, maxWidth: 440) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              fixed(quality: 80, toFormat: JPG, width: 1200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  const { getOnClick, ImageViewer } = useImageViewer();

  const screenshots = useMemo(() => {
    const screenshots = data?.screenshots?.edges.map(
      (s) => s.node.childImageSharp
    );
    return screenshots.slice(from || 0, to || screenshots.length);
  }, [data]);
  if (!screenshots) {
    return null;
  }

  return (
    <>
      <ImageGrid>
        {screenshots.map((s, i) => {
          if (!s || !s.fluid || !s.fixed) {
            return null;
          }

          return (
            <a
              key={s.fixed.src}
              href={s.fixed.src}
              target="_blank"
              onClick={getOnClick(s.fixed.src)}
            >
              <Img key={i} fluid={s.fluid} />
            </a>
          );
        })}
      </ImageGrid>

      <ImageViewer />
    </>
  );
}

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-template-rows: auto;
  column-gap: 16px;
  row-gap: 16px;
`;
