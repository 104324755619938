import React from "react";
import styled from "styled-components";
import DiscordLogo from "../images/discord.svg";

export default function JoinDiscord() {
  return (
    <A href="https://discord.gg/dM6y6YR">
      <DiscordLogo /> <span>Unserem Discord beitreten</span>
    </A>
  );
}

const A = styled.a`
  &,
  &:any-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f1f4f8;
    text-decoration: none;
    font-size: 2rem;
  }

  svg {
    width: 80px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  @media (max-width: 575px) {
    &,
    &:any-link {
      font-size: 1.5rem;
    }

    svg {
      width: 60px;
    }
  }
`;
