import React from "react";
import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLElement> {
  variant?: "blue" | "discord";
  children?: React.ReactNode;
}

export default function BasicSection({ variant, children, ...rest }: Props) {
  return (
    <Container variant={variant}>
      <TextContainer {...rest}>{children}</TextContainer>
    </Container>
  );
}

const Container = styled.section<{ variant?: "blue" | "discord" }>`
  background: ${background};
  color: ${color};

  a,
  :any-link {
    color: ${color};
  }
`;

function background({ variant }: { variant?: "blue" | "discord" }) {
  switch (variant) {
    case "blue":
      return "#1d456c";
    case "discord":
      return "#7388da";
    default:
      return "#f1f4f8";
  }
}

function color({ variant }: { variant?: "blue" | "discord" }) {
  switch (variant) {
    case "blue":
      return "#f1f4f8";
    case "discord":
      return "#f1f4f8";
    default:
      return "#1d456c";
  }
}

const TextContainer = styled.div`
  max-width: 960px;
  padding: 128px 32px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 927px) {
    padding: 64px 32px;
  }
`;
