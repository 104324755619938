import React from "react";
import styled from "styled-components";

interface Props {
  children?: React.ReactNode;
}

export default function Columns({ children }: Props) {
  return <ColumnsGrid>{children}</ColumnsGrid>;
}

const ColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;
